import { Component } from "react";
import { Card, Alert } from "react-bootstrap";
import Widget from "../common/widget";
import Message from "./../common/message";
import Converter from "../../converter";

export default class MessageNode extends Component {
  render() {
    // Find messages data
    const [ messagesValue, warning ] = Widget.loadObjectValue(
      this.props.data.shellInterface, this.props.data.component, "messages", "messages");
    let messages = messagesValue ? messagesValue["messages"] : null;

    // Trim messages to max length
    if ( messages ) {
      const maxMessages = Converter.getComponentPortValueData(this.props.data.component, "max_messages");
      if ( maxMessages ) {
        messages = messages.slice(0, maxMessages);
      }
    }

    // Render messages content
    return (
      <Widget data={this.props.data}>
        {warning &&
          <Alert variant="info">
            {warning}
          </Alert>
        }
        {(!warning && !messages) &&
          <Alert variant="warning">
            Messages object contains no value.
          </Alert>
        }
        {(!warning && messages) &&
          <>
            {messages.map((messageValue, index) => (
              <Message value={messageValue} />
            ))}
          </>
        }
      </Widget>
    );
  }
}
