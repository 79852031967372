import { Component } from "react";
import { Button } from "react-bootstrap";
import { LuClipboardCopy } from "react-icons/lu";
import Tooltip from "./tooltip";

export default class Clipboard extends Component {
  static to(text) {
    try {
      navigator.clipboard.writeText(text);
    } catch (error) {
      console.error(error.message);
    }
  }

  static from() {
    const promise = new Promise((resolve, reject) => {
      try {
        navigator.clipboard.readText().then((text) => {
          resolve(text);
        });
      } catch (error) {
        console.error(error.message);
        reject();
      }
    });
    return promise;
  }

  render() {
    return (
      <Tooltip text="Copy to clipboard">
        <Button size="sm" variant="light" onClick={(event) => {
          Clipboard.to(this.props.text);
        }}>
          <LuClipboardCopy />
        </Button>
      </Tooltip>
    );
  }
}
