import React, { Component } from 'react';
import { Container, Button, Form, Modal, Row, Col } from 'react-bootstrap';
import Select from "react-select";
import AceEditor from "react-ace";

export default class CreateExecutionModal extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      definitionOptions: null,
      selectedDefinitionOption: null,
      inputData: null,
      tracing: null,
    };
  }

  onEnter = () => {
    let definitionOptions = [];
    for ( const definition of this.props.definitions ) {
      definitionOptions.push({
        value: definition,
        label: definition["alias"]
      });
    }

    this.setState({
      definitionOptions: definitionOptions,
      selectedDefinitionOption: definitionOptions[0],
      inputData: "[]",
      tracing: JSON.stringify({
        "log_data": false
      }, null, 2)
    });
  }

  isValidInputData() {
    try {
      JSON.parse(this.state.inputData);
      return true;
    } catch ( error ) {
      return false;
    }
  }

  isValidTracing() {
    try {
      JSON.parse(this.state.tracing);
      return true;
    } catch ( error ) {
      return false;
    }
  }

  handleCreate = () => {
    // Check form validity
    if ( !this.form.current.reportValidity() ) {
      return;
    }
    let inputData = null;
    let tracing = null;
    try {
      inputData = JSON.parse(this.state.inputData);
      tracing = JSON.parse(this.state.tracing);
    } catch ( error ) {
      return;
    }

    const execution = {
      definition: this.state.selectedDefinitionOption.value,
      inputData: inputData,
      tracing: tracing
    };
    this.props.handleCreate(execution);

    // Close modal
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        {...this.props}
        onEnter={this.onEnter}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create a New Execution
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={this.form} onSubmit={(event) => {
            event.preventDefault();
            this.handleCreate();
          }}>
            <Form.Group>
              <Form.Label>
                Execution runs a workflow of interconnected processors, each performing specific tasks.
                It happens within a session, maintaining shared state throughout.
              </Form.Label>
            </Form.Group>
            <hr/>

            <Form.Group>
              <Form.Label>
                <strong>Workflow Definition</strong> <span className="text-muted">(required)</span>
              </Form.Label>
              <Row>
                <Col sm={6}>
                  <Select
                    options={this.state.definitionOptions}
                    value={this.state.selectedDefinitionOption}
                    onChange={(option) => {
                      this.setState({ selectedDefinitionOption: option });
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Specify workflow definition to be executed.
              </Form.Text>
            </Form.Group>
            <hr/>

            <Form.Group>
              <Form.Label>
                <strong>Input Data</strong>
              </Form.Label>
              <Row>
                <Col sm={11}>
                  <AceEditor
                    name="body-editor" width="100%" height="100px" mode="json" theme="monokai" wrapEnabled="true"
                    value={this.state.inputData}
                    onChange={(newValue) => {
                      this.setState({inputData: newValue});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                The list of input data values to be associated with aliased data processors.
              </Form.Text>
              <Form.Control.Feedback type="invalid" style={{"display": this.isValidInputData() ? "none" : "block"}}>
                Invalid JSON provided
              </Form.Control.Feedback>
            </Form.Group>
            <hr/>

            <Form.Group>
              <Form.Label>
                <strong>Tracing</strong>
              </Form.Label>
              <Row>
                <Col sm={11}>
                  <AceEditor
                    name="body-editor" width="100%" height="100px" mode="json" theme="monokai" wrapEnabled="true"
                    value={this.state.tracing}
                    onChange={(newValue) => {
                      this.setState({tracing: newValue});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Tracing properties of a new execution.
              </Form.Text>
              <Form.Control.Feedback type="invalid" style={{"display": this.isValidTracing() ? "none" : "block"}}>
                Invalid JSON provided
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>Close</Button>
          <Button variant="success" onClick={this.handleCreate}>Create</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
