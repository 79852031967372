import React, { Component } from 'react';
import { Container, Card, Alert, ButtonGroup, Button, Form } from 'react-bootstrap';
import { GoBook, GoPlus, GoPencil, GoCheck, GoX } from 'react-icons/go';
import { marked, Renderer } from 'marked';
import './md.css';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-monokai";

export default class MarkdownView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editMode: false,
      editing: false,
      editingError: null,
      item: null,
      content: null
    };
  }

  componentDidMount() {
    this.loadContent();
  }

  /**
   * Data
   */

  loadContent() {
    this.setState({loading: true});

    const revisionId = this.props.revision.id;
    const metadata = this.props.metadata;
    this.props.app.api.revisionsIdMetadataAliasGet(revisionId, metadata)
      .then(response => {
        this.setState({loading: false});

        this.findContentItem(response.data.items);
      }, error => {
        // Failing silently for now
        this.setState({loading: false});
      });
  }

  findContentItem(items) {
    if ( !items || 0 == items.length ) {
      return null;
    }

    // Just pick the first one available.
    const item = items[0];
    this.setState({item: item});
  }

  /**
   * Editing
   */

  handleEnableEditMode = () => {
    this.setState({
      editMode: true,
      editing: false,
      editingError: null,
      content: this.state.item ? this.state.item.content : ""
    });
  }

  handleCancelEditMode = () => {
    this.setState({
      editMode: false,
      editing: false,
      editingError: null
    });
  }

  handleApplyChanges = () => {
    this.setState({
      editMode: true,
      editing: true,
      editingError: null
    });

    const item = {
      content: this.state.content
    };
    if ( this.state.item ) {
      this.props.app.api.revisionsMetadataIdPut(this.state.item.id, item)
        .then(response => {
          item.id = this.state.item.id;
          this.setState({
            item: item,
            editMode: false,
            editing: false,
            editingError: null
          });
        }, error => {
          this.setState({
            editMode: true,
            editing: false,
            editingError: error.message
          });
        });
    } else {
      this.props.app.api.revisionsIdMetadataAliasPost(this.props.revision.id, this.props.metadata, item)
        .then(response => {
          item.id = response.data.item_id;
          this.setState({
            item: item,
            editMode: false,
            editing: false,
            editingError: null
          });
        }, error => {
          this.setState({
            editMode: true,
            editing: false,
            editingError: error.message
          });
        });
    }
  }

  onContentChange = (content) => {
    this.setState({
      content: content
    });
  }

  /**
   * UX
   */

  renderMarkdownCard(id, title, content, canEdit) {
    var renderer = new Renderer();
    renderer.table = function(table) {
      let contents = marked.parse(table.raw);
      contents = contents.replace("<table>", '<table class="table table-hover">');
      return marked.parse(contents);
    };

    var renderedMarkdown = {__html: marked.parse(content, { renderer: renderer })};
    var card = (
      <Card id="markdown">
        <Card.Header>
          &nbsp;
          <GoBook />
          &nbsp;&nbsp;
          <strong>{title}</strong>

          {canEdit &&
            <span className="float-right">
              <ButtonGroup size="sm" className="header-options">
                <Button
                  variant="light"
                  onClick={this.handleEnableEditMode}>
                  <GoPencil/>
                </Button>
              </ButtonGroup>
            </span>
          }
        </Card.Header>
        <Card.Body dangerouslySetInnerHTML={renderedMarkdown}>
        </Card.Body>
      </Card>
    );
    return card;
  }

  render() {
    const canEdit = this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision);
    return (
      <>
        {this.state.loading &&
          <Container className="blob-header-row content-row">
            <Alert variant="primary">
              Loading {this.props.metadata}...
            </Alert>
          </Container>
        }
        {!this.state.loading &&
          <>
            {!this.state.editMode &&
              <>
                {!this.state.item && canEdit &&
                  <Container className="blob-header-row content-row md-create">
                    <Button
                      type="submit"
                      variant="success"
                      size="sm"
                      onClick={this.handleEnableEditMode}>
                      <GoPlus/> Add {this.props.title}
                    </Button>
                    <Form.Text muted>
                      {this.props.createPrompt}
                    </Form.Text>
                  </Container>
                }
                {this.state.item &&
                  <Container className="blob-header-row content-row">
                    {this.renderMarkdownCard(this.props.metadata, this.props.title, this.state.item.content, canEdit)}
                  </Container>
                }
              </>
            }
            {this.state.editMode &&
              <Container className="blob-header-row content-row">
                {!this.state.editing &&
                  <Card>
                    <Card.Header>
                      <GoBook/>
                      &nbsp;<strong>{this.props.title}</strong>

                      <span className="float-right">
                        <ButtonGroup size="sm" className="header-options">
                          <Button
                            variant="light"
                            onClick={this.handleApplyChanges}>
                            <GoCheck/>
                          </Button>
                          <Button
                            variant="light"
                            onClick={this.handleCancelEditMode}>
                            <GoX/>
                          </Button>
                        </ButtonGroup>
                      </span>
                    </Card.Header>
                    <Card.Body>
                      <AceEditor
                        name="content-editor"
                        width="100%"
                        height="500px"
                        mode="markdown"
                        theme="monokai"
                        commands={[{
                          name: 'applyChanges',
                          bindKey: {win: 'Ctrl-Enter', mac: 'Command-Enter'},
                          exec: () => { this.handleApplyChanges() }
                        }]}
                        onChange={this.onContentChange}
                        value={this.state.content}
                      />

                      {this.state.editingError &&
                        <Alert variant="warning">
                          {this.state.editingError}
                        </Alert>
                      }
                    </Card.Body>
                  </Card>
                }
                {this.state.editing &&
                  <Alert variant="primary">
                    Applying changes...
                  </Alert>
                }
              </Container>
            }
          </>
        }
      </>
    );
  }
}
