import { GoX, GoPlus, GoWorkflow, GoDatabase, GoGlobe } from 'react-icons/go';
import { HiMiniArrowLeftStartOnRectangle, HiMiniArrowRightStartOnRectangle } from "react-icons/hi2";
import { LuBrainCircuit } from "react-icons/lu";
import { RiRobot2Line } from "react-icons/ri";
import { PiVectorThree } from "react-icons/pi";
import { FaCode } from "react-icons/fa6";
import { LuText } from "react-icons/lu";
import { TfiWidget } from "react-icons/tfi";
import { GiProcessor } from "react-icons/gi";

export default class Components {
  constructor() {
    this.componentIcons = [
      {"prefix": "flow.entered", "icon": <HiMiniArrowRightStartOnRectangle />},
      {"prefix": "flow.exited", "icon": <HiMiniArrowLeftStartOnRectangle />},
      {"prefix": "flow.", "icon": <GoWorkflow />},
      {"prefix": "data.", "icon": <GoDatabase />},
      {"prefix": "web.", "icon": <GoGlobe />},
      {"prefix": "genai.chat", "icon": <LuBrainCircuit />},
      {"prefix": "genai.agent", "icon": <RiRobot2Line />},
      {"prefix": "database.vector.", "icon": <PiVectorThree />},
      {"prefix": "logic.", "icon": <FaCode />},
      {"prefix": "text.", "icon": <LuText />},
      {"prefix": "widget.", "icon": <TfiWidget />},
    ];
  }

  getComponentIcon(type) {
    for ( const icon of this.componentIcons ) {
      if ( type.startsWith(icon.prefix) ) {
        return icon.icon;
      }
    }
    return <GiProcessor />;
  }
}