import { Component } from 'react';
import { Card } from 'react-bootstrap';
import Converter from "../../converter";

export default class Widget extends Component {
  static loadObjectValue(shellInterface, component, route, type) {
    const alias = Converter.getComponentPortValueData(component, route);
    if ( !alias ) {
      return [null, `"${route}" value is not specified.`];
    }

    const state = shellInterface.getState();
    if ( !state.session ) {
      return [null, "Session is not selected."];
    }

    const value = state.sessionData[alias];
    if ( !value ) {
      return [null, `"${alias}" data is not available (not loaded yet or not present within selected session).`];
    }

    const valueType = value["type"];
    if ( type != valueType ) {
      return [null, `Specified alias points at an object of "${valueType}" type. "${type}" object expected.`];
    }

    return [value, null];
  }

  render() {
    // Widget dimensions
    const width = Converter.getComponentPortValueData(this.props.data.component, "width");
    const height = Converter.getComponentPortValueData(this.props.data.component, "height");

    // Base rendering
    return (
      <Card>
        <Card.Header>
          <span className="playground-widget-name">
            {this.props.data.component.name}
          </span>
          <span className="float-right text-muted">
            {this.props.data.component.type}
          </span>
        </Card.Header>
        <Card.Body className="nodrag nowheel" style={{
          "width": `${width}px`,
          "height": `${height}px`,
          "max-height": `${height}px`,
          "overflow": "scroll"
        }}>
          {this.props.children}
        </Card.Body>
      </Card>
    );
  }
}
