import React, { Component } from 'react';
import { Container, Row, Col, Card, ButtonGroup, Button, Alert, Form, Badge } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { IoIosPlay } from "react-icons/io";
import { FaRegStopCircle } from "react-icons/fa";
import { GoX, GoInfo, GoTrash } from "react-icons/go";
import { MdOutlineExpandMore } from "react-icons/md";
import Navigation from 'context/nav';
import Utils from 'context/utils';
import CreateExecutionModal from './create';

export default class SessionView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: true,
      definitions: null,
      definitionsIndex: null,
      executions: null,
      lastExecutionId: null,
      createExecutionModelShow: false,
      progress: false,
      error: false,
      message: null,
    };
  }

  componentDidMount() {
    this.loadDefinitions();
  }

  updateProgress = (progress, error, message) => {
    this.setState({
      progress: progress,
      error: error,
      message: message
    });
  }

  loadDefinitions() {
    // Render progress
    this.updateProgress(true, false, "Loading workflow definitions...");

    // Fetch the list
    const body = {
      engine: "workflow_blobhub",
      command: "list_definitions",
      category: "workflow"
    };
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        // Parse response
        const definitions = response.data.definitions;
        let definitionsIndex = {};
        for ( const definition of definitions ) {
          definitionsIndex[definition["id"]] = definition;
        }

        // Clear progress
        this.updateProgress(false, false, null);

        // Notify the view that definitions have been fetched
        this.setState({
          definitions: definitions,
          definitionsIndex: definitionsIndex
        }, () => {
          this.loadExecutions();
        });
      }, error => {
        this.updateProgress(false, true, "Failed to fetch workflow definitions.");
      });
  }

  loadExecutions(more) {
    // Render progress
    this.updateProgress(true, false, "Loading executions...");

    // Fetch the list
    const sessionId = this.props.match.params.sectionId;
    const body = {
      "engine": "workflow_blobhub",
      "command": "list_executions",
      "session_id": sessionId
    };
    // Page results from where we left off the last time
    if ( more ) {
      body["start_execution_id"] = this.state.lastExecutionId;
    }
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        // Parse response
        const executions = response.data.executions;
        const lastExecutionId = response.data.last_execution_id;
        for ( const execution of executions ) {
          execution["definition_alias"] = this.state.definitionsIndex[execution["definition_id"]]["alias"];
        }

        // Clear progress
        this.updateProgress(false, false, null);

        // Merge results with previously loaded ones in case of paging
        if ( more ) {
          executions.push(...this.state.executions);
        }

        // Update component state
        this.setState({
          executions: executions,
          lastExecutionId: lastExecutionId
        });
      }, error => {
        // Display failure message
        this.updateProgress(false, true, "Failed to fetch the list of executions: " + error.message);
      });
  }

  createExecution(executionDescription) {
    // Render progress
    this.updateProgress(true, false, "Creating a new execution...");

    // Create execution
    const sessionId = this.props.match.params.sectionId;
    const body = {
      "engine": "workflow_blobhub",
      "command": "create_execution",
      "session_id": sessionId,
      "definition_id": executionDescription.definition["id"],
      "input_data": executionDescription.inputData,
      "tracing": executionDescription.tracing
    };
    this.props.app.api.revisionsIdDataCommandPost(this.props.revision.id, body)
      .then( response => {
        const executionId = response.data["execution"]["id"];

        // Navigate to newly created execution
        this.proceedToExecution(executionId);
      }, error => {
        // Render error state
        this.updateProgress(false, true, "Failed to create execution: " + error.message);
      });
  }

  proceedToExecution(executionId) {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;
    const sessionId = this.props.match.params.sectionId;
    this.props.history.push(Navigation.workflowPath(orgId, blobId, "debugger", sessionId, executionId));
  }

  render() {
    const columns = [
      {
        "field": "id",
        "headerName": "Execution ID",
        "filter": true,
        "flex": 6,
        "sortable": false,
        cellRenderer: params => {
          return (
            <a href="#" onClick={(event) => {
              event.preventDefault();
              this.proceedToExecution(params.value);
            }}>{params.value}</a>
          );
        }
      },
      {
        "field": "created_at",
        "headerName": "Created At (UTC)",
        "filter": false,
        "flex": 8,
        "sortable": true,
        "sort": "desc",
        cellRenderer: params => {
          return ( <> { Utils.formatDateTime(params.value) } </> );
        }
      },
      {
        "field": "definition_alias",
        "headerName": "Definition",
        "filter": true,
        "flex": 4,
        "sortable": true,
        cellRenderer: params => {
          return ( <> {params.value} </> );
        }
      },
      {
        "field": "status",
        "headerName": "Status",
        "filter": true,
        "flex": 3,
        "sortable": true,
        cellRenderer: params => {
          return ( <Badge bg="info" className="title-tag-badge">{params.value}</Badge> );
        }
      }
    ];

    return (
      <>
        {( this.state.progress || this.state.error ) &&
          <Container>
            <Row>
              <Col>
                <Form.Group>
                  <Alert variant={this.state.progress ? "primary" : "warning"}>
                    {this.state.message}
                  </Alert>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        }
        <Container>
          <Row>
            <Col sm={this.state.details ? 8 : 12}>
              <Card>
                <Card.Header>
                  Executions
                  <span className="float-right">
                    {this.state.executions &&
                      <>
                        Total fetched: <strong>{this.state.executions.length}</strong>
                        {this.state.lastExecutionId &&
                          <> | More available</>
                        }
                      </>
                    }
                    {!this.state.details &&
                      <ButtonGroup size="sm" className="header-options">
                        <Button variant="light" onClick={() => { this.setState({ details: true }) }}><GoInfo/></Button>
                      </ButtonGroup>
                    }
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div
                      className="ag-theme-quartz"
                    >
                      <AgGridReact
                        rowData={this.state.executions}
                        columnDefs={columns}
                        domLayout='autoHeight'
                        pagination={false}
                      />
                    </div>
                  </Card.Text>
                  {this.state.lastExecutionId && !this.state.progress &&
                    <Card.Text>
                      <Button variant="light" size="sm" onClick={() => {
                        this.loadExecutions(true);
                      }}><MdOutlineExpandMore /> Load More Results</Button>
                    </Card.Text>
                  }
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className={this.state.details ? "" : "hidden"}>
              <Card>
                <Card.Header>
                  Actions
                  <span className="float-right">
                    <ButtonGroup size="sm" className="header-options">
                      <Button variant="light" onClick={() => { this.setState({ details: false }) }}><GoX/></Button>
                    </ButtonGroup>
                  </span>
                </Card.Header>
                <Card.Body>
                  {!this.state.progress ?
                    <>
                      {( this.state.definitions && this.state.definitions.length > 0 ) ?
                        <>
                          <Card.Text>
                            <Button variant="success" size="sm" onClick={() => {
                              this.setState({ createExecutionModelShow: true });
                            }}><IoIosPlay /> New Execution</Button>
                          </Card.Text>
                          <Card.Text>
                            Execution runs a workflow of interconnected processors, each performing specific tasks.
                            It happens within a session, maintaining shared state throughout.
                          </Card.Text>
                        </> : <>
                          <Card.Text>
                            <Button variant="success" size="sm" disabled>
                              <IoIosPlay /> New Execution
                            </Button>
                          </Card.Text>
                          <Card.Text>
                            <Alert variant="warning">
                              No workflow definitions found in the revision.
                              Create the first workflow definition to be able to execute it.
                            </Alert>
                          </Card.Text>
                        </>
                      }

                      <Card.Text>
                        <Button disabled={true} variant="success" size="sm" onClick={() => {
                          // TODO:
                        }}><FaRegStopCircle /> Close Session</Button>
                      </Card.Text>
                      <Card.Text>
                        Closing a session stops all active executions and effectively turns it into a readonly one.
                      </Card.Text>

                      <Card.Text>
                        <Button disabled={true}  variant="success" size="sm" onClick={() => {
                          // TODO:
                        }}><GoTrash /> Delete Session</Button>
                      </Card.Text>
                      <Card.Text>
                        Deletes session, its state, and belonging executions.
                      </Card.Text>
                    </> : <>
                      <Card.Text>
                        Not available while loading is in progress.
                      </Card.Text>
                    </>
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <CreateExecutionModal
          app={this.props.app}
          revision={this.props.revision}
          definitions={this.state.definitions}
          show={this.state.createExecutionModelShow}
          onHide={() => this.setState({ createExecutionModelShow: false })}
          handleCreate={(execution) => this.createExecution(execution)}
        />
      </>
    );
  }
}
