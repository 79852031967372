import React, { Component } from 'react';
import { Card, Form, Badge, Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import { GoX, GoPlus, GoPencil } from "react-icons/go";
import Select from "react-select";
import EditMessageContentModal from "./content";
import Utils from "context/utils";

export default class DetailsPane extends Component {
  constructor(props) {
    super(props);

    this.basicTypes = new Set(["text", "integer", "float"]);

    this.state = {
      editMessageContentModalShow: false,
      portValue: null
    };
  }

  removeEdge = (edge) => {
    const edgeIndex = this.props.shell.edges.indexOf(edge);
    if ( -1 == edgeIndex ) {
      return;
    }

    let edges = this.props.shell.edges;
    edges.splice(edgeIndex, 1);
    this.props.shell.workflowInterface.setEdges(edges, true);
  }

  renderConnection(edge, destination, node, nodesIndex) {
    return (
      <>
        <Card.Text>
          <table>
            <tr>
              <td>
                <Badge variant="secondary" className="title-tag-badge">{edge.label}</Badge>
              </td>
              <td style={{
                "width": "100%",
                "padding-left": "9px"
              }}>
                <span className="text-muted">connected to</span>&nbsp;
                <strong>
                  {nodesIndex[edge[destination]].data.processor.name}
                </strong>
              </td>
              <td>
                <ButtonGroup size="sm" className="header-options">
                  <Button variant="light" onClick={(event) => this.removeEdge(edge)}><GoX/></Button>
                </ButtonGroup>
              </td>
            </tr>
          </table>
        </Card.Text>
      </>
    );
  }

  removePort = (node, port) => {
    const portIndex = node.data.processor["ports"].indexOf(port);
    if ( -1 != portIndex ) {
      node.data.processor["ports"].splice(portIndex, 1);
      this.props.shell.shellInterface.markModified();
    }
  }

  getPortValue(port) {
    const type = port["value"]["type"];
    let value = undefined;
    if ( "text" == type ) {
      value = port["value"]["text"];
    } else if ( "integer" == type ) {
      value = port["value"]["integer"];
    } else if ( "float" == type ) {
      value = port["value"]["float"];
    }
    return value;
  }

  updatePortValueFromEvent(port, event) {
    const type = port["value"]["type"];
    let value = event.target.value;
    try {
      if ( "text" == type ) {
      } else if ( "integer" == type ) {
        const intValue = parseInt(value);
        value = ( `${intValue}` === value ) ? intValue : value;
      } else if ( "float" == type ) {
        const floatValue = parseFloat(value);
        value = ( `${floatValue}` === value ) ? floatValue : value;
      }
    } catch ( error ) {}
    if ( "text" == type ) {
      port["value"]["text"] = value;
    } else if ( "integer" == type ) {
      port["value"]["integer"] = value;
    } else if ( "float" == type ) {
      port["value"]["float"] = value;
    }
    this.props.shell.shellInterface.markModified();
  }

  editPortMessageContent(node, port) {
    this.setState({
      editMessageContentModalShow: true,
      portValue: port["value"]
    });
  }

  renderInlineValue(portWrapper, destination, node, nodesIndex) {
    let port = portWrapper.port;
    const portManifest = portWrapper.portManifest;

    if ( !portManifest ) {
      return ( <></> );
    }

    const header = (
      <Card.Text>
        <Badge variant="secondary" className="title-tag-badge">{port.route}</Badge>
        &nbsp;&nbsp;<span className="text-muted">inline</span>
        <span className="float-right">
          <ButtonGroup size="sm" className="header-options">
            <Button variant="light" onClick={(event) => this.removePort(node, port)}><GoX/></Button>
          </ButtonGroup>
        </span>
      </Card.Text>
    );

    if ( this.basicTypes.has(port["value"]["type"]) ) {
      return (
        <>
          {header}
          <Card.Text>
            <Row className="align-items-center">
              {portManifest["value"]["range"] &&
                <Col sm={8}>
                  <Form.Control
                    type="range" size="sm"
                    min={portManifest["value"]["range"]["from"]}
                    max={portManifest["value"]["range"]["to"]}
                    step={portManifest["value"]["range"]["step"]}
                    value={this.getPortValue(port)}
                    onChange={(event) => {
                      this.updatePortValueFromEvent(port, event);
                    }}
                  />
                </Col>
              }
              <Col sm={portManifest["value"]["range"] ? 4 : 12}>
                <Form.Control
                  type="text" size="sm"
                  value={this.getPortValue(port)}
                  onChange={(event) => {
                    this.updatePortValueFromEvent(port, event);
                  }}
                />
              </Col>
            </Row>
          </Card.Text>
        </>
      );
    }

    if ( "boolean" == port["value"]["type"] ) {
      let portKey = port["category"] + "-" + ( port["route"] || "default" );
      let portManifest = node.data.manifest["ports_index"][portKey];

      return (
        <>
          {header}
          <Card.Text>
            <Form.Check
              type="switch"
              id={"port_" + portKey}
              label={portManifest.value.label}
              checked={port["value"]["flag"]}
              onChange={(event) => {
                port["value"]["flag"] = event.target.checked;
                this.props.shell.shellInterface.markModified();
              }}
            />
          </Card.Text>
        </>
      );
    }

    if ( "choice" == port["value"]["type"] ) {
      let portKey = port["category"] + "-" + ( port["route"] || "default" );
      let portManifest = node.data.manifest["ports_index"][portKey];
      let options = [];
      let selectedOption = null;
      for ( const portValueOption of portManifest["value"]["options"] ) {
        const option = {
          value: portValueOption.id,
          label: portValueOption.label
        };
        options.push(option);
        if ( portValueOption.id == port.value.id ) {
          selectedOption = option;
        }
      }

      return (
        <>
          {header}
          <Card.Text>
            <Select
              options={options}
              value={selectedOption}
              onChange={(option) => {
                port.value.id = option.value;
                this.props.shell.shellInterface.markModified();
              }}
            />
          </Card.Text>
        </>
      );
    }

    if ( "credential" == port["value"]["type"] ) {
      let options = [];
      let selectedOption = null;
      for ( const credential of ( this.props.shell.credentials || [] ) ) {
        const credentialType = portManifest["value"]["credential_type"];
        const disabled = ( credentialType && ( credentialType != credential["type"] ) );
        const option = {
          value: credential,
          label: credential.alias,
          isDisabled: disabled
        }
        options.push(option);
        if ( port["value"]["alias"] == credential.alias ) {
          selectedOption = option;
        }
      }

      return (
        <>
          {header}
          <Card.Text>
            <Select
              options={options}
              value={selectedOption}
              onChange={(option) => {
                port.value.alias = option.value.alias;
                this.props.shell.shellInterface.markModified();
              }}
            />
          </Card.Text>
        </>
      );
    }

    if ( "message" == port["value"]["type"] ) {
      return (
        <>
          {header}
          <Card.Text>
            <Row className="align-items-center">
              <Col xs="auto" style={{ "padding-right": "0px" }}>
                Role
              </Col>
              <Col style={{ "padding-right": "0px" }}>
                <Form.Control
                  type="text" size="sm"
                  value={port["value"]["message"]["role"]}
                  onChange={(event) => {
                    let value = event.target.value;
                    port["value"]["message"]["role"] = value;
                    this.props.shell.shellInterface.markModified();
                  }}
                />
              </Col>
              <Col xs="auto" style={{ "padding-right": "0px" }}>
                Content
              </Col>
              <Col xs="auto">
                <ButtonGroup size="sm" className="header-options">
                  <Button variant="light" onClick={(event) => this.editPortMessageContent(node, port)}><GoPencil/></Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Card.Text>
        </>
      );
    }

    return ( <></> );
  }

  addPort = (node, port) => {
    if ( !node.data.processor["ports"] ) {
      node.data.processor["ports"] = [];
    }
    node.data.processor["ports"].push(port);
    this.props.shell.shellInterface.markModified();
  }

  renderInlineAdd(portWrapper, destination, node, nodesIndex) {
    let port = portWrapper.port;
    return (
      <>
        <Card.Text>
          <table>
            <tr>
              <td>
                <Badge variant="secondary" className="title-tag-badge">{port.route}</Badge>
              </td>
              <td style={{
                "width": "100%",
                "padding-left": "9px"
              }}>
                <span className="text-muted">inline value missing</span>
              </td>
              <td>
                <ButtonGroup size="sm" className="header-options">
                  <Button variant="success" onClick={(event) => this.addPort(node, port)}><GoPlus/></Button>
                </ButtonGroup>
              </td>
            </tr>
          </table>
        </Card.Text>
      </>
    );
  }

  renderInlineMissing(portWrapper, destination, node, nodesIndex) {
    let port = portWrapper.port;
    return (
      <>
        <Card.Text>
          <Badge variant="secondary" className="title-tag-badge">{port.route}</Badge>
          &nbsp;&nbsp;<span className="text-muted">inline editing not supported</span>
        </Card.Text>
      </>
    );
  }

  renderEdge(edge, destination, node, nodesIndex) {
    if ( "connection" == edge.mode ) {
      return this.renderConnection(edge, destination, node, nodesIndex);
    } else if ( "port" == edge.mode ) {
      return this.renderInlineValue(edge, destination, node, nodesIndex);
    } else if ( "add" == edge.mode ) {
      return this.renderInlineAdd(edge, destination, node, nodesIndex);
    } else { // if ( "missing" == edge.mode ) {
      return this.renderInlineMissing(edge, destination, node, nodesIndex);
    }
  }

  renderEdges(label, edgesByCategory, category, destination, node, nodesIndex) {
    const edges = edgesByCategory[category];
    if ( 0 == edges.length ) {
      return (<></>);
    }

    return (
      <>
        <Card.Text as="h6">{label}</Card.Text>
        <hr />
        {edgesByCategory[category].map((edge, index) => {
          return this.renderEdge(edge, destination, node, nodesIndex);
        })}
      </>
    );
  }

  render() {
    const node = this.props.shell.details.data;

    let nodesIndex = {};
    for ( const node of this.props.shell.nodes ) {
      nodesIndex[node.id] = node;
    }

    // Fill in existing connections
    let edgesByCategory = {
      "flow_input": [],
      "flow_output": [],
      "data_input": [],
      "data_output": [],
      "provider_input": [],
      "provider_reference": [],
      "data_reference": [],
    }
    for ( const edge of this.props.shell.edges ) {
      if ( node.id == edge.target ) {
        edge.mode = "connection";
        edgesByCategory[edge.targetHandle].push(edge);
      }
      if ( node.id == edge.source ) {
        edge.mode = "connection";
        edgesByCategory[edge.sourceHandle].push(edge);
      }
    }

    // Prepare port manifests by route
    let portManifestsByRoute = {};
    for ( const portManifest of node.data.manifest["ports"] ) {
      portManifestsByRoute[portManifest["route"]] = portManifest;
    }

    // Populate inline "data input" ports
    let inlinePorts = {};
    for ( const port of ( node.data.processor.ports || [] ) ) {
      const portWrapper = {
        mode: "port",
        port: port,
        portManifest: portManifestsByRoute[port["route"]]
      };
      edgesByCategory["data_input"].push(portWrapper);
      inlinePorts[port["route"]] = port;
    }

    // Populate missing "data input" ports
    for ( const portManifest of node.data.manifest["ports"] ) {
      // Filter out all but "data input" ports
      if ( "data_input" != portManifest["category"] ) {
        continue;
      }

      // Filter out ports with inline values
      if ( inlinePorts[portManifest["route"]] ) {
        continue;
      }

      // Create port definition
      let mode = "add";
      let port = {
        "category": "data_input",
        "route": portManifest["route"],
        "value": {
          "type": null
        }
      };
      if ( portManifest["value"] ) {
        port["value"]["type"] = portManifest["value"]["type"];
      }
      if ( "text" == port["value"]["type"] ) {
        port["value"]["text"] = portManifest["value"]["default"] || "";
      } else if ( "boolean" == port["value"]["type"] ) {
        port["value"]["flag"] = portManifest["value"]["default"] || 0;
      } else if ( "integer" == port["value"]["type"] ) {
        port["value"]["integer"] = portManifest["value"]["default"] || 0;
      } else if ( "float" == port["value"]["type"] ) {
        port["value"]["float"] = portManifest["value"]["default"] || 0.0;
      } else if ( "choice" == port["value"]["type"] ) {
        port["value"]["id"] = portManifest["value"]["default"] || portManifest["value"]["options"][0]["id"];
      } else if ( "credential" == port["value"]["type"] ) {
        port["value"]["alias"] = null;
      } else if ( "message" == port["value"]["type"] ) {
        if ( portManifest["value"]["default"] ) {
          port["value"]["message"] = Utils.deepCopy(portManifest["value"]["default"]);
        } else {
          port["value"]["message"] = {
            "role": "user",
            "content": []
          };
        }
      } else {
        // In-place editing of everything else is not currently supported
        mode = "missing";
      }

      // Render new entry
      const portWrapper = {
        mode: mode,
        port: port,
        portManifest: portManifest
      };
      edgesByCategory["data_input"].push(portWrapper);
    }

    return (
      <>
        <Card.Text as="h6">General</Card.Text>
        <hr />
        <Card.Text>Basics</Card.Text>
        <Card.Text>
          <table className="properties">
            <tr>
              <td className="prop-name-column">ID</td>
              <td className="prop-value-column"><code>{node.id}</code></td>
            </tr>
            <tr>
              <td className="prop-name-column">Type</td>
              <td className="prop-value-column"><code>{node.data.processor["type"]}</code></td>
            </tr>
          </table>
        </Card.Text>
        <Card.Text>Name</Card.Text>
        <Card.Text>
          <Form.Control
            type="text" size="sm"
            value={node.data.processor["name"]}
            onChange={(event) => {
              node.data.processor["name"] = event.target.value;
              this.props.shell.shellInterface.markModified();
            }}
          />
        </Card.Text>

        {this.renderEdges("Data Inputs", edgesByCategory, "data_input", "target", node, nodesIndex)}
        {this.renderEdges("Data Outputs", edgesByCategory, "data_output", "target", node, nodesIndex)}
        {this.renderEdges("Flow Inputs", edgesByCategory, "flow_input", "source", node, nodesIndex)}
        {this.renderEdges("Flow Outputs", edgesByCategory, "flow_output", "target", node, nodesIndex)}
        {this.renderEdges("Provider Inputs", edgesByCategory, "provider_input", "target", node, nodesIndex)}
        {this.renderEdges("Provider References", edgesByCategory, "provider_reference", "source", node, nodesIndex)}
        {this.renderEdges("Data References", edgesByCategory, "data_reference", "source", node, nodesIndex)}

        <EditMessageContentModal
          show={this.state.editMessageContentModalShow}
          onHide={() => this.setState({ editMessageContentModalShow: false })}
          shell={this.props.shell}
          portValue={this.state.portValue}
        />
      </>
    );
  }
}
