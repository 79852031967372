import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { LuClipboardCopy, LuClipboardPaste } from "react-icons/lu";
import Tooltip from "../../controls/common/tooltip";
import Clipboard from "../../controls/common/clipboard";
import Converter from "../converter";
import Utils from "context/utils";

export default class DetailsPane extends Component {
  exportToClipboard() {
    const converter = new Converter(this.props.shell.manifest);
    const definition = converter.reactFlowToBlobhubDefinition(this.props.shell.nodes, this.props.shell.edges);
    const text = JSON.stringify(definition, null, 2)
    Clipboard.to(text);
  }

  importFromClipboard() {
    Clipboard.from().then((text) => {
      let definition = null;
      try {
        definition = JSON.parse(text);
        if ( definition["components"].length < 1 ) {
          return;
        }
      } catch (error) {
        console.error(error.message);
        return;
      }

      const converter = new Converter(this.props.shell.manifest);
      const [nodes, edges] = converter.blobhubDefinitionToReactFlow(definition);

      // Offset new workflow to the right of an existing one
      // 1. Calculate the right boundary of an existing workflow
      let rightMost = 0;
      for ( const node of this.props.shell.nodes ) {
        if ( node.position.x > rightMost ) {
          rightMost = node.position.x;
        }
      }
      // 2. Calculate the left boundary of a newly pasted workflow
      let leftMost = 0;
      for ( const node of nodes ) {
        if ( node.position.x < leftMost ) {
          leftMost = node.position.x;
        }
      }
      // 3. Compute offset
      const offset = Math.min(leftMost - rightMost, 0) * -1 + 250;
      // 4. Apply offset
      for ( const node of nodes ) {
        node.position.x += offset;
      }

      // Regenerate workflow IDs
      let idMapping = {};
      for ( const node of nodes ) {
        const newId = Utils.generateUniqueId();
        idMapping[node.id] = newId;
        node.id = newId;
        node.data.processor.id = newId;
      }
      for ( const edge of edges ) {
         edge.id = Utils.generateUniqueId();
         edge.source = idMapping[edge.source];
         edge.target = idMapping[edge.target];
      }

      // Register enw nodes and edges
      const mergedNodes = this.props.shell.nodes.concat(nodes);
      const mergedEdges = this.props.shell.edges.concat(edges);
      this.props.shell.workflowInterface.setNodes(mergedNodes, true);
      this.props.shell.workflowInterface.setEdges(mergedEdges, true);
    });
  }

  render() {
    return (
      <>
        {this.props.shell.current.item &&
          <>
            <Card.Text as="h6">Workflow</Card.Text>
            <hr />
            <Card.Text>Basics</Card.Text>
            <Card.Text>
              <table className="properties">
                <tr>
                  <td className="prop-name-column">ID</td>
                  <td className="prop-value-column"><code>{this.props.shell.current.item["id"]}</code></td>
                </tr>
              </table>
            </Card.Text>
          </>
        }

        <Card.Text as="h6">Selection</Card.Text>
        <hr />
        <Card.Text>
          Select a single processor or connection in the workflow editor to see its properties here.
        </Card.Text>

        {this.props.shell.current.item &&
          <>
            <Card.Text as="h6">Export</Card.Text>
            <hr />
            <Card.Text>
              <Row>
                <Col className="align-self-center">
                  Export workflow definition to clipboard.
                </Col>
                <Col sm={"auto"}>
                  <span className="float-right">
                    <Tooltip text="Export to clipboard">
                      <Button size="sm" variant="light" onClick={(event) => { this.exportToClipboard(); }}>
                        <LuClipboardCopy />
                      </Button>
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </Card.Text>

            <Card.Text as="h6">Import</Card.Text>
            <hr />
            <Card.Text>
              <Row>
                <Col className="align-self-center">
                  Import workflow definition from clipboard.
                </Col>
                <Col sm={"auto"}>
                  <span className="float-right">
                    <Tooltip text="Import from clipboard">
                      <Button size="sm" variant="light" onClick={(event) => { this.importFromClipboard(); }}>
                        <LuClipboardPaste />
                      </Button>
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </Card.Text>
          </>
        }
      </>
    );
  }
}
