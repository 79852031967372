import React, { Component } from 'react';
import { Container, Button, Alert, Table, Badge, Modal, Form, Row, Col } from 'react-bootstrap';
import Select from "react-select";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import 'ace-builds/webpack-resolver';
import { GoTrash } from "react-icons/go";
import { PiPassword } from "react-icons/pi";

class AddCredentialModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeOption: null,
      alias: "",
      description: "",
      body: "{}"
    };

    this.form = React.createRef();
  }

  onEnter = () => {
    this.typeOptions = [];
    for ( const provider of this.props.manifest["provider_manifests"] ) {
      const typeOption = {
        value: provider,
        label: provider.label
      };
      this.typeOptions.push(typeOption);
    }
    const selectionTypeOption = this.typeOptions[0];

    this.setState({
      typeOption: selectionTypeOption,
      alias: "",
      description: "",
      body: JSON.stringify(selectionTypeOption.value.credentials.template, null, 2)
    });
  }

  handleAddCredential = () => {
    // Check form validity
    if ( !this.form.current.reportValidity() ) {
      return;
    }
    let body = null
    try {
      body = JSON.parse(this.state.body);
    } catch ( error ) {
      return;
    }

    // Initiate processing
    const credential = {
      type: this.state.typeOption.value.type,
      alias: this.state.alias,
      body: body
    };
    if ( this.state.description ) {
      credential["description"] = this.state.description;
    }
    this.props.handleAddCredential(credential);

    // Close modal
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        onEnter={this.onEnter}
        onHide={this.props.onHide}
        show={this.props.show}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add a New Credential
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={this.form} onSubmit={(event) => {
            event.preventDefault();
            this.handleAddCredential();
          }}>
            <Form.Group>
              <Form.Label>
                <strong>Type</strong> <span className="text-muted">(required)</span>
              </Form.Label>
              <Row>
                <Col sm={4}>
                  <Select
                    options={this.typeOptions}
                    value={this.state.typeOption}
                    onChange={(option) => {
                      this.setState({
                        typeOption: option,
                        body: JSON.stringify(option.value.credentials.template, null, 2)
                      });
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Identifies external entity to be accessed on behalf of the user.
              </Form.Text>
            </Form.Group>
            <hr/>

            <Form.Group>
              <Form.Label>
                <strong>Alias</strong> <span className="text-muted">(required)</span>
              </Form.Label>
              <Row>
                <Col sm={3}>
                  <Form.Control
                    required type="text" size="sm" autoFocus
                    value={this.state.alias}
                    onChange={(event) => {
                      this.setState({alias: event.target.value});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                A unique alias used to reference the credentials entry created here.
              </Form.Text>
              <Form.Text muted>
                Alias must confirm to the following requirements:
                <ul>
                  <li>minimum of 6 characters;</li>
                  <li>maximum of 32 characters;</li>
                  <li>only alphanumeric characters along with <strong>-</strong>,<strong>_</strong> are allowed;</li>
                  <li>must be unique for the user.</li>
                </ul>
              </Form.Text>
            </Form.Group>
            <hr/>

            <Form.Group>
              <Form.Label>
                <strong>Body</strong> <span className="text-muted">(required)</span>
              </Form.Label>
              <Row>
                <Col sm={11}>
                  <AceEditor
                    name="body-editor" width="100%" height="100px" mode="json" theme="monokai" wrapEnabled="true"
                    value={this.state.body}
                    onChange={(newValue) => {
                      this.setState({body: newValue});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Body of the credential where the secrets are stored.
              </Form.Text>
              <Form.Text muted>
                Make sure to double-check the contents of the body since it cannot be reviewed or modified later.
              </Form.Text>
            </Form.Group>
            <hr/>

            <Form.Group>
              <Form.Label>
                <strong>Description</strong>
              </Form.Label>
              <Row>
                <Col sm={9}>
                  <Form.Control
                    type="text" size="sm" autoFocus
                    value={this.state.description}
                    onChange={(event) => {
                      this.setState({description: event.target.value});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Provide optional description to be able to differentiate credentials stored here.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>Close</Button>
          <Button variant="success" onClick={this.handleAddCredential}>Add</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default class Credentials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manifest: null,
      credentials: null,
      progress: false,
      error: false,
      message: null,
      interface: {
      }
    }
  }

  updateProgress = (progress, error, message) => {
    this.setState({
      progress: progress,
      error: error,
      message: message
    });
  }

  componentDidMount() {
    this.loadUserCredentials();
    this.loadWorkflowManifest();
  }

  loadWorkflowManifest() {
    // Initiate loading
    this.props.app.api.workflowManifestGet("workflow")
      .then( response => {
        // Extract manifest from the response body
        let manifest = response.data.manifest;

        // Notify the view that the manifest has been fetched
        this.setState({ manifest: manifest });
      }, error => {
        // Nothing explicit is rendered but inability to load manifest prevents users
        // from being able to create new credentials.
      });
  }

  loadUserCredentials() {
    // Indicate progress
    this.updateProgress(true, false, "Loading user credentials...");

    // Query the list of user's credentials
    const userId = this.props.user.id;
    this.props.app.api.usersIdCredentialsGet(userId)
      .then(response => {
        const credentials = response.data.credentials;

        // Indicate success
        this.updateProgress(false, false, null);

        // Update application state
        this.setState({ credentials: credentials });
      }, error => {
        this.updateProgress(false, true, "Failed to load credentials: " + error.message);
      });
  }

  handleAddCredential(credential) {
    // Indicate progress
    this.updateProgress(true, false, "Creating new credential...");

    // Create new credential
    const userId = this.props.user.id;
    this.props.app.api.usersIdCredentialsPost(userId, credential)
      .then(response => {
        // Indicate success
        this.updateProgress(false, false, null);

        // Reload the list
        this.loadUserCredentials();
      }, error => {
        this.updateProgress(false, true, "Failed to create credential: " + error.message);
      });
  }

  handleDeleteCredential(credential) {
    // Indicate progress
    this.updateProgress(true, false, "Deleting credential entry " + credential.alias + "...");

    // Initiate deletion
    this.props.app.api.usersCredentialsIdDelete(credential.id)
      .then(response => {
        // Indicate success
        this.updateProgress(false, false, null);

        // Reload the list of credentials
        this.loadUserCredentials();
      }, error => {
        // Indicate failure
        this.updateProgress(false, true, "Failed to delete credential entry " + credential.alias + ": " + error.message);
      });
  }

  render() {
    return (
      <>
        <h5>Credentials</h5>
        <hr/>
        <div>
          Credentials are used to access third-party services on behalf of a user.
        </div>

        {this.state.credentials && 0 != this.state.credentials.length &&
          <div className="content-row">
            <Table hover bordered style={{"margin-bottom": "0px"}}>
              <tbody>
                {this.state.credentials.map((credential, index) => {
                  return (
                    <tr>
                      <td>
                        <div className="d-flex">
                          <div>
                            <PiPassword/>&nbsp;&nbsp;
                            <Badge variant="secondary" className="title-tag-badge">{credential.type}</Badge>
                            <code><strong>&nbsp;{credential.alias}</strong></code>
                            {credential.description &&
                              <>&nbsp;&nbsp;{credential.description}</>
                            }
                          </div>
                          <div className="ml-auto">
                            {!this.state.progress &&
                              <Button variant="danger" className="btn-icon"
                                onClick={() => this.handleDeleteCredential(credential)}
                                ><GoTrash /></Button>
                            }
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        }

        <div className="content-row">
          Credentials cannot be edited or viewed.
          To make any modifications, they must be removed and re-added.
          Maintaining the same alias ensures that the credentials are correctly referenced by the associated workloads.
        </div>

        <div className="content-row">
          BlobHub does not monitor or track the consumption of dependent services.
          It is the responsibility of users to set appropriate quotas and monitor their own utilization of those services.
        </div>

        {( !this.state.progress && null != this.state.credentials && null != this.state.manifest ) &&
          <div className="content-row">
            <Button
              variant="success"
              size="sm"
              onClick={() => this.setState({ addCredentialModalShow: true })}><PiPassword/> New
            </Button>

            <span className="text-muted text-note">
              &nbsp;&nbsp;&nbsp;Create a new credential
            </span>
          </div>
        }

        {( this.state.progress || this.state.error ) &&
          <div className="content-row">
            <Alert variant={this.state.progress ? "primary" : "warning"}>
              {this.state.message}
            </Alert>
          </div>
        }

        <AddCredentialModal
          show={this.state.addCredentialModalShow}
          onHide={() => this.setState({ addCredentialModalShow: false })}
          handleAddCredential={(credential) => this.handleAddCredential(credential)}
          manifest={this.state.manifest}
        />
      </>
    );
  }
}
